import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem;
  padding-bottom: 10rem;
  background-color: ${({ theme }) => theme.colors.light[50]};

  margin: 0 auto;
  @media (${({ theme }) => theme.medias.desktop}) {
    max-width: 100rem;
    padding: 3rem 10rem;
    padding-bottom: 10rem;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  gap: 2rem;
  width: 100%;
  max-width: 70rem;

  & > h1 {
    font-size: 3rem;
    text-align: center;
    font-family: "Merriweather Regular";
    & > span {
      color: #f76c6f;
    }
  }
  & > p {
    font-size: 1.6rem;
    text-align: center;
    font-family: "Montserrat Regular";
    & > span {
      font-family: "Montserrat Medium";
      color: #f76c6f;
    }
  }
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  gap: 2rem;
  width: 100%;

  & > h2 {
    font-size: 2.4rem;
    text-align: center;
    font-family: "Merriweather Regular";
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (${({ theme }) => theme.medias.desktop}) {
      flex-direction: row;
      justify-content: space-between;
    }
    & > ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      width: 100%;
      list-style: none;
      margin-bottom: 2rem;
      & > li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
        width: 100%;
        & > img {
          width: 8rem;
          height: 8rem;
          min-height: 8rem;
          min-width: 8rem;
          object-fit: cover;
          border-radius: 1rem;
        }
        & > div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 1rem;
          width: 100%;
          height: 100%;
          padding: 1rem 0;
          & > h3 {
            font-size: 1.8rem;
            text-align: left;
            font-family: "Montserrat Bold";
          }
          & > p {
            font-size: 1.6rem;
            text-align: left;
            font-family: "Montserrat Regular";
          }
        }
      }
    }
  }
`;

export const NumbersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  gap: 2rem;
  max-width: 50rem;
  @media (${({ theme }) => theme.medias.desktop}) {
  }
  & > h2 {
    font-size: 2.4rem;
    text-align: center;
    font-family: "Merriweather Regular";
  }
  & > ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    & > li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 2rem;
      width: 100%;

      & > p {
        font-size: 1.8rem;
        text-align: left;
        font-family: "Montserrat Regular";
        & > span {
          font-family: "Montserrat Medium";
          color: #f76c6f;
        }
      }
    }
  }
`;

export const HowItWorksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  gap: 2rem;
  width: 100%;
  max-width: 70rem;
  & > h2 {
    font-size: 2.4rem;
    text-align: center;
    font-family: "Merriweather Regular";
    & > span {
      color: #f76c6f;
    }
  }
  & > ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    & > li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      & > h4 {
        font-family: "Montserrat Medium";
        font-size: 1.8rem;
        border: 1px solid #f76c6f;
        color: #f76c6f;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        min-width: 4rem;
        min-height: 4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      & > div.conector {
        width: 100%;
        height: 1px;
        max-width: 2rem;
        max-height: 1px;
        background-color: #f76c6f;
      }
      & > div.box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
        border: 1px solid #f76c6f;
        border-radius: 2rem;
        padding: 2rem;
        & > p {
          font-size: 1.8rem;
          text-align: left;
          font-family: "Montserrat Regular";
          & > span {
            font-family: "Montserrat Medium";
            color: #f76c6f;
          }
          & > ul {
            text-align: left;
            padding-left: 3rem;
          }
        }
      }
    }
  }
`;

export const TargetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  width: 100%;
  max-width: 50rem;
  & > h2 {
    font-size: 2.4rem;
    text-align: center;
    font-family: "Merriweather Regular";
    margin-bottom: 2rem;
    & > span {
      color: #f76c6f;
    }
  }
  & > h3 {
    width: 100%;
    text-align: left;
    font-size: 1.8rem;
    text-transform: uppercase;
    font-family: "Montserrat Regular";
    margin-bottom: 1rem;
  }
  & > p {
    width: 100%;
    text-align: left;
    font-size: 1.8rem;
    font-family: "Montserrat Medium";
    margin-bottom: 3rem;
  }
  & > ul {
    width: 100%;
    text-align: left;
    margin-left: 5rem;
    & > li {
      font-size: 1.8rem;
    }
  }
`;
