import { StyledTitle } from "libs/styled-components/styles";
import { Container, ListContainer } from "./styles";
import FirebaseStorageService from "libs/firebase/FirebaseStorageService";
import { saveLog } from "services/logs";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import GlobalContext from "context";
import NewlywedsPDF from "assets/images/newlyweds-pdf.png";
import Demonstration from "assets/images/demonstration.png";
import Spinner from "components/Spinner";

const SupportContent = () => {
  const { user } = useContext(GlobalContext);
  const [isCopied, setIsCopied] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const timeout = useRef();

  const link = useMemo(() => {
    return `https://webapp.capsuladotempo.com/open-event/wtWAjZzdBgdCDZXHoYvB`;
  }, []);

  const onCopyToClipboard = async () => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);
    saveLog({
      action: "copy-link",
      user: user.id,
      data: {
        link: link,
        email: user.email,
      },
    });
    timeout.current = setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };

  const onDownload = async () => {
    setIsDownloading(true);
    const url = "marketing/apresentacao-cdt.pdf";
    const filename = "Cápsula do Tempo - Apresentação.pdf";
    try {
      await FirebaseStorageService.downloadFile(url, filename);
      saveLog({
        action: "download-pdf",
        user: user.id,
        data: {
          fileurl: url,
          filename: filename,
          email: user.email,
        },
      });
    } catch (error) {
      const errorMsg = `onDownload [${JSON.stringify(error)}]`;
      console.log(errorMsg);
      setIsDownloading(false);
    }

    setIsDownloading(false);
  };

  const onOpenLink = async () =>
    saveLog({
      action: "open-link",
      user: user.id,
      data: {
        link: link,
        email: user.email,
      },
    });

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);
  return (
    <Container>
      <p>
        Preparamos materiais de apoio para facilitar sua comunicação com seus
        contatos e levar a inovação da <span>Cápsula do Tempo</span> para mais
        pessoas.
      </p>
      <StyledTitle>Materiais de apoio</StyledTitle>
      <ListContainer>
        <ul>
          <li>
            <img src={Demonstration} alt="cdt gift" />
            <div>
              <h3>Evento Demonstração</h3>
              <p>Permite gravar vídeo mensagens e assistí-las em seguida.</p>
            </div>
            <button type="button" onClick={onCopyToClipboard}>
              {isCopied ? "Copiado" : "Copiar link"}
            </button>
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              onClick={onOpenLink}
            >
              Abrir
            </a>
          </li>
          <li>
            <img src={NewlywedsPDF} alt="cdt gift" />
            <div>
              <h3>Apresentação PDF</h3>
              <p>
                Apresentação explicativa sobre a Cápsula do Tempo para os
                noivos.
              </p>
            </div>
            <button onClick={onDownload} disabled={isDownloading}>
              {isDownloading ? (
                <Spinner width="8rem" height="2rem" />
              ) : (
                "Baixar"
              )}
            </button>
          </li>
        </ul>
      </ListContainer>
    </Container>
  );
};
export default SupportContent;
