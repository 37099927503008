import GlobalContext from "context";
import { useContext } from "react";

const { Container } = require("./styles");

const FreeEventsBalance = () => {
  const { user } = useContext(GlobalContext);
  if (!user.freeEvents) return null;

  return (
    <Container>
      <h3>Eventos gratuitos: {user.freeEvents}</h3>
      <p>
        {user.freeEvents > 1
          ? `Os próximos ${user.freeEvents} eventos criados com o
        seu link serão gratuitos`
          : `O próximo evento criado com o
        seu link será gratuito`}
        .
      </p>
    </Container>
  );
};
export default FreeEventsBalance;
