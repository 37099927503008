import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 1rem;
  padding-left: 3rem;
  justify-content: space-between;
  align-items: center;

  background-color: ${(p) => p.theme.colors.dark[700]};
  color: ${(p) => p.theme.colors.text.light};
  z-index: 10;
`;
