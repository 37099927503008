import styled, { css } from "styled-components";

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  & > button {
    width: 100%;
    display: flex;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    padding: 1rem;
    min-height: 5.2rem;
    border-radius: 0.5rem;
    border: 1px solid ${(p) => p.theme.colors.dark[100]};
    color: ${(p) => p.theme.colors.text.light};
  }

  ${({ model }) => {
    if (model) {
      if (model === "google") {
        return css`
          & > button {
            justify-content: space-between;
            color: ${(p) => p.theme.colors.text.dark};
            background-color: ${(p) => p.theme.colors.light[50]};
            border: 1px solid ${(p) => p.theme.colors.dark[100]};
          }
        `;
      }
      if (model === "new") {
        return css`
          & > button {
            border-radius: 0;
            border: none;
            font-weight: bold;
            color: ${(p) => p.theme.colors.primary[500]};
            background-color: transparent;
          }
        `;
      }
      if (model === "login") {
        return css`
          & > button {
            background-color: ${(p) => p.theme.colors.success[900]};
          }
        `;
      }
      if (model === "forgot") {
        return css`
          padding: 0;
          margin-top: -1rem;
          margin-bottom: 2rem;
          & > button {
            justify-content: flex-start;
            padding: 0;
            margin: 0;

            min-height: 0;
            border-radius: 0;
            border: none;
            font-size: 1.8rem;
            color: ${(p) => p.theme.colors.primary[500]};
            background-color: transparent;
          }
        `;
      }
    } else {
      return css`
        & > button {
          background-color: ${(p) => p.theme.colors.primary[700]};
        }
      `;
    }
  }}
`;
