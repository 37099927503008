import Header from "components/Header";
import GlobalContext from "context";
import Register from "modules/register/screens/Main";
import { useContext, useMemo } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";

const PublicRouter = ({ children }) => {
  const { user, authUser } = useContext(GlobalContext);

  const isVerifyEmail = useMemo(() => {
    return authUser ? authUser.emailVerified : false;
  }, [authUser]);

  if (!isVerifyEmail && authUser) {
    return (
      <>
        <Header />
        <Layout>{children}</Layout>
      </>
    );
  }

  if (!user && authUser) {
    return (
      <>
        <Header />
        <Layout>
          <Register />
        </Layout>
      </>
    );
  }

  return (
    <>
      <Header />
      <Layout>
        {authUser && isVerifyEmail ? <Navigate to="/" /> : children}
      </Layout>
    </>
  );
};
export default PublicRouter;

const Layout = styled.div`
  overflow-y: auto;
  height: -webkit-fill-available;
  height: 100%;
`;
