import Spinner from "components/Spinner";

const { Label, LoadingContainer } = require("./styles");

const InputSelect = ({
  label,
  field,
  errors,
  register,
  required,
  validate,
  options,
  defaultOption,
  isLoading,
}) => {
  if (isLoading)
    return (
      <LoadingContainer>
        <Spinner color="#131968" />
      </LoadingContainer>
    );

  return (
    <Label className={errors[field] && "error"}>
      {label}
      <select defaultValue="" {...register(field, { required, validate })}>
        <option value="" disabled hidden>
          {defaultOption}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errors[field] && (
        <span>
          {errors[field].message ? errors[field].message : "Obrigatório"}
        </span>
      )}
    </Label>
  );
};

export default InputSelect;
