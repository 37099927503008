import { useForm } from "react-hook-form";
import { Container, ShowText } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import InputText from "components/InputText";
import { useContext, useEffect, useState } from "react";
import { StyledForm, StyledLine } from "libs/styled-components/styles";
import LoginButton from "components/LoginButton";
import { useGetStates, useGetCities, useCreatePartner } from "services/user";
import GlobalContext from "context";
import Spinner from "components/Spinner";
import InputSelect from "components/InputSelect";
import { saveLog } from "services/logs";

const CompleteForm = ({ authUser }) => {
  const { userHandler } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const { mutate } = useCreatePartner();
  const selectedState = watch("state");
  const { states, isLoading: isLoadingState } = useGetStates();
  const { cities, isLoading: isLoadingCity } = useGetCities(selectedState);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await saveLog({
        action: "register",
        user: authUser.uid,
        data: {
          method: "google",
          userEmail: authUser.email,
        },
      });
    } catch (error) {
      console.log("savelog.error", error.message);
    }
    try {
      const newData = {
        ...data,
        id: authUser.uid,
        name: data.name,
        email: authUser.email,
        phone: data.phone,
        state: data.state,
        city: data.city,
        instagram: data.instagram,
      };
      mutate(newData, {
        onSuccess: (result) => {
          setLoading(false);
          userHandler(result);

          navigate(`../${location.search}`);
        },
      });
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (selectedState) {
      setValue("city", "");
    }
  }, [selectedState, setValue]);

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <StyledLine>
          <ShowText>
            <label>
              Email<span>{authUser.email}</span>
            </label>
          </ShowText>
        </StyledLine>
        <StyledLine>
          <InputText
            label="Nome"
            field="name"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        <StyledLine>
          <InputText
            label="Telefone"
            field="phone"
            type="tel"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>

        <StyledLine>
          <InputSelect
            label="Estado"
            field="state"
            errors={errors}
            required={true}
            register={register}
            defaultOption="Selecione um estado"
            options={states}
            isLoading={isLoadingState}
          />
        </StyledLine>

        {selectedState !== "" && selectedState !== undefined && (
          <StyledLine>
            <InputSelect
              label="Cidade"
              field="city"
              errors={errors}
              required={true}
              register={register}
              defaultOption="Selecione uma cidade"
              options={cities}
              isLoading={isLoadingCity}
            />
          </StyledLine>
        )}
        <StyledLine>
          <InputText
            label="Instagram"
            field="instagram"
            errors={errors}
            required={false}
            register={register}
          />
        </StyledLine>

        {error && <p>{error}</p>}
        <LoginButton type="submit">
          {loading ? <Spinner /> : "Concluir"}
        </LoginButton>
      </StyledForm>
    </Container>
  );
};

export default CompleteForm;
