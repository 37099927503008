import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.light[700]};
  padding-bottom: 6rem;
  & > ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    list-style: none;
    padding-top: 2rem;
    gap: 1rem;
    & > li {
      padding-left: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0.4rem;
      font-size: 1.6rem;
      font-family: "Montserrat Regular";
      text-transform: uppercase;
      & > a {
        font-family: "Montserrat SemiBold";
        color: ${({ theme }) => theme.colors.primary[300]};
        text-decoration: none;
      }
    }
  }
`;
