import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.light[400]};
  color: ${({ theme }) => theme.colors.text.dark};
  padding: 2rem;
  gap: 1rem;
  & > h3 {
    font-size: 2rem;
    font-family: "Montserrat Medium";
  }
  & > p {
    font-size: 1.6rem;
  }
`;
