import { useForm } from "react-hook-form";
import { Container } from "./styles";
import FirebaseAuthService from "libs/firebase/FirebaseAuthService";
import { useLocation, useNavigate } from "react-router-dom";
import InputText from "components/InputText";
import { useEffect, useState } from "react";
import { StyledForm, StyledLine } from "libs/styled-components/styles";
import LoginButton from "components/LoginButton";
import { GoogleIcon } from "assets/svg";
import { useCreatePartner, useGetCities, useGetStates } from "services/user";
import Spinner from "components/Spinner";
import InputSelect from "components/InputSelect";

const Form = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const { mutate, isLoading } = useCreatePartner();

  const selectedState = watch("state");
  const { states, isLoading: isLoadingState } = useGetStates();
  const { cities, isLoading: isLoadingCity } = useGetCities(selectedState);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await FirebaseAuthService.registerUser(
        data.email,
        data.password,
        data.name
      );

      const newData = {
        id: response.user.uid,
        name: data.name,
        email: data.email,
        phone: data.phone,
        state: data.state,
        city: data.city,
        instagram: data.instagram,
      };

      mutate(newData, {
        onSuccess: (result) => {
          setLoading(false);
          navigate(`../${location.search}`);
        },
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLoginWithGoogle = async () => {
    try {
      await FirebaseAuthService.loginWithGoogle();
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleLogin = () => {
    navigate(`../login/${location.search}`);
  };

  useEffect(() => {
    if (selectedState) {
      setValue("city", "");
    }
  }, [selectedState, setValue]);

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <StyledLine>
          <InputText
            label="Nome"
            field="name"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        <StyledLine>
          <InputText
            label="Telefone"
            field="phone"
            type="tel"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        <StyledLine>
          <InputText
            label="Email"
            field="email"
            type="email"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        <StyledLine>
          <InputText
            label="Senha"
            field="password"
            type="password"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        <StyledLine>
          <InputSelect
            label="Estado"
            field="state"
            errors={errors}
            required={true}
            register={register}
            defaultOption="Selecione um estado"
            options={states}
            isLoading={isLoadingState}
          />
        </StyledLine>

        {selectedState !== "" && selectedState !== undefined && (
          <StyledLine>
            <InputSelect
              label="Cidade"
              field="city"
              errors={errors}
              required={true}
              register={register}
              defaultOption="Selecione uma cidade"
              options={cities}
              isLoading={isLoadingCity}
            />
          </StyledLine>
        )}
        <StyledLine>
          <InputText
            label="Instagram"
            field="instagram"
            errors={errors}
            required={false}
            register={register}
          />
        </StyledLine>
        {error && <p>{error}</p>}
        <LoginButton type="submit">
          {loading || isLoading ? <Spinner /> : "Criar"}
        </LoginButton>
      </StyledForm>
      <LoginButton type="button" model="google" onClick={handleLoginWithGoogle}>
        <GoogleIcon height="30px" width="30px" />
        Criar com o Google
        <span></span>
      </LoginButton>
      <h3>Já possui conta?</h3>
      <LoginButton type="button" model="new" onClick={handleLogin}>
        Acessar
      </LoginButton>
    </Container>
  );
};

export default Form;
