import {
  Container,
  FeaturesContainer,
  HowItWorksContainer,
  NumbersContainer,
  TargetContainer,
  TextContainer,
} from "./styles";
import Giftbox from "assets/images/giftbox.jpg";
import Easy from "assets/images/easy.jpg";
import Logistics from "assets/images/logistics.jpg";
import Unlimited from "assets/images/unlimited.jpg";

const PageContent = () => {
  return (
    <Container>
      <TextContainer>
        <h1>
          O que é a <br />
          <span>Cápsula do Tempo</span>?
        </h1>
        <p>
          Vivemos a era da <span>experiência</span>, onde as memórias criadas a
          partir da vivência, que se tornam <span>inesquecíveis</span>, estão
          sendo mais <span>valorizadas</span> que as coisas materiais, que com o
          tempo podem estragar, quebrar ou tornarem-se obsoletas.
        </p>
        <p>
          Na <span>Cápsula do Tempo</span> oferecemos aos noivos a oportunidade
          de <span>reviver</span> o dia mais importante de suas vidas pelo olhar
          dos seus convidados!
        </p>
      </TextContainer>
      <FeaturesContainer>
        <h2>Características</h2>
        <div>
          <ul>
            <li>
              <img src={Giftbox} alt="cdt gift" />
              <div>
                <h3>Único</h3>
                <p>É uma inovação no segmento de casamentos.</p>
              </div>
            </li>
            <li>
              <img src={Easy} alt="cdt easy" />
              <div>
                <h3>Fácil de usar</h3>
                <p>Os convidados usam o próprio celular para gravar.</p>
              </div>
            </li>
          </ul>
          <ul>
            <li>
              <img src={Logistics} alt="cdt logistics" />
              <div>
                <h3>Processos simples</h3>
                <p>Cuidamos da coleta, armazenamento e envio dos vídeos.</p>
              </div>
            </li>
            <li>
              <img src={Unlimited} alt="cdt unlimited" />
              <div>
                <h3>Ilimitado</h3>
                <p>Não existe limite de vídeo mensagens. É ilimitado!</p>
              </div>
            </li>
          </ul>
        </div>
      </FeaturesContainer>
      <NumbersContainer>
        <h2>Nossos Números</h2>
        <ul>
          <li>
            <p>
              <span>2019</span> aconteceu nosso primeiro evento oficial
            </p>
          </li>
          <li>
            <p>
              <span>+9000</span> vídeo mensagens gravadas
            </p>
          </li>
          <li>
            <p>
              <span>+400</span> eventos realizados
            </p>
          </li>
        </ul>
      </NumbersContainer>
      <HowItWorksContainer>
        <h2>
          Como promover a <br /> <span>Cápsula do Tempo</span>?
        </h2>
        <ul>
          <li>
            <h4>1</h4>
            <div className="conector" />
            <div className="box">
              <p>
                Você compartilha o seu link único e o usuário acessa nosso site,{" "}
                <span>se registra</span> e cria um evento.
              </p>
            </div>
          </li>
          <li>
            <h4>2</h4>
            <div className="conector" />
            <div className="box">
              <p>
                Após fazer o pagamento é liberado o acesso ao QR-Code para
                impressão e o link do evento.
              </p>
            </div>
          </li>
          <li>
            <h4>3</h4>
            <div className="conector" />
            <div className="box">
              <p>
                No dia do evento, os convidados leem o QR-Code com seus
                celulares e começam a gravar vídeos mensagens.
              </p>
            </div>
          </li>
          <li>
            <h4>4</h4>
            <div className="conector" />
            <div className="box">
              <p>
                Após o evento, os vídeos são enviados em 3 datas futuras:
                <ul>
                  <li>1 mês após o evento</li>
                  <li>6 meses após o evento</li>
                  <li>1 ano após o evento</li>
                </ul>
              </p>
            </div>
          </li>
        </ul>
      </HowItWorksContainer>
      <TargetContainer>
        <h2>
          O público da <br /> <span>Cápsula do Tempo</span>
        </h2>
        <h3>Mercado de atuação</h3>
        <p>Casamentos</p>
        <h3>Públicos</h3>
        <ul>
          <li>Noivos</li>
          <li>Madrinhas</li>
          <li>Padrinhos</li>
          <li>Pessoas que procuram presentes de casamento fora do comum</li>
        </ul>
      </TargetContainer>
    </Container>
  );
};

export default PageContent;
