import styled from "styled-components";

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-right: 2rem;
  font-size: 2rem;

  & > input {
    width: 100%;
    font-size: 2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid ${(p) => p.theme.colors.dark[300]};
  }
  &.error {
    color: ${(p) => p.theme.colors.danger[600]};
  }
  &:last-child {
    margin: 0;
  }
`;
