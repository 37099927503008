import GlobalContext from "context";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Container, CopyButton } from "./styles";
import { saveLog } from "services/logs";

const PartnerLinkSection = () => {
  const { user } = useContext(GlobalContext);
  const [isCopied, setIsCopied] = useState(false);
  const timeout = useRef();

  const link = useMemo(() => {
    return `https://capsuladotempo.com/?cdt_par=${user.email}`;
  }, [user]);

  const onCopyToClipboard = async () => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);
    saveLog({
      action: "copy-link",
      user: user.id,
      data: {
        link: link,
        email: user.email,
      },
    });
    timeout.current = setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  return (
    <Container>
      <label>
        <h3>Seu link de parceiro é:</h3>
        <span>{link}</span>
      </label>
      <CopyButton isCopied={isCopied} onClick={onCopyToClipboard}>
        {isCopied ? "Copiado!" : "Clique para copiar"}
      </CopyButton>
    </Container>
  );
};

export default PartnerLinkSection;
