import LoginButton from "components/LoginButton";
import GlobalContext from "context";
import FirebaseAuthService from "libs/firebase/FirebaseAuthService";
import {
  StyledContent,
  StyledHeader,
  StyledLayout,
} from "libs/styled-components/styles";
import { useContext } from "react";
import { Content } from "./styles";

const VerifyEmail = () => {
  const { authUser } = useContext(GlobalContext);

  const handleResendEmail = async () => {
    await FirebaseAuthService.sendEmailVerification(authUser);
  };
  return (
    <StyledLayout>
      <StyledHeader>
        <h1>Verifique seu email</h1>
      </StyledHeader>
      <StyledContent>
        <Content>
          <h2>Você deve verificar seu email para concluir seu cadastro.</h2>

          <p>
            Não recebeu e-mail? <br />
            <span>Verifique sua caixa de spam</span>
          </p>
          <LoginButton model="new" onClick={handleResendEmail}>
            Clique aqui para reenviar o email de confirmação
          </LoginButton>
          <LoginButton onClick={() => window.location.reload(false)}>
            Já confirmei!
          </LoginButton>
        </Content>
      </StyledContent>
    </StyledLayout>
  );
};
export default VerifyEmail;
