import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;

  & > h3 {
    margin-top: 6rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    max-width: 40rem;
    width: 40rem;
  }
`;
