import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  & > div {
    color: ${(p) => (p.color ? p.color : p.theme.colors.text.light)};
    font-size: 1.2rem;
    font-family: "FARRAY Regular";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > span {
      font-size: 2em;
      font-weight: bold;
    }
  }
  & > label {
    margin-top: 0.4rem;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 1em;
    text-align: center;
    letter-spacing: 0.7rem;
    width: 100%;
  }
`;
