import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding: 4rem 2rem;
  & > h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  & > p {
    text-align: center;
    font-size: 1.8rem;
    & > span {
      font-weight: bold;
    }
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    max-width: 40rem;
    width: 40rem;
  }
`;
