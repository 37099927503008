import { useForm } from "react-hook-form";
import { Container } from "./styles";
import FirebaseAuthService from "libs/firebase/FirebaseAuthService";
import { useLocation, useNavigate } from "react-router-dom";
import InputText from "components/InputText";
import { useState } from "react";
import { StyledForm, StyledLine } from "libs/styled-components/styles";
import LoginButton from "components/LoginButton";
import { GoogleIcon } from "assets/svg";

const Form = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await FirebaseAuthService.loginUser(data.email, data.password);
      navigate("/");
    } catch (error) {
      setError("Usuário não encontrado ou senha incorreta");
    }
  };

  const handleLoginWithGoogle = async () => {
    try {
      await FirebaseAuthService.loginWithGoogle();
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleRegister = () => {
    navigate(`../register/${location.search}`);
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledLine>
          <InputText
            label="Email"
            field="email"
            type="email"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        <StyledLine>
          <InputText
            label="Senha"
            field="password"
            type="password"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        <LoginButton
          onClick={handleForgotPassword}
          type="button"
          model="forgot"
        >
          Esqueci minha senha
        </LoginButton>
        {error && <p>{error}</p>}
        <LoginButton type="submit" model="login">
          Login
        </LoginButton>
      </StyledForm>
      <LoginButton type="button" model="google" onClick={handleLoginWithGoogle}>
        <GoogleIcon height="30px" width="30px" />
        Login com o Google
        <span></span>
      </LoginButton>
      <h3>Não possui conta?</h3>
      <LoginButton type="button" model="new" onClick={handleRegister}>
        Criar Conta
      </LoginButton>
    </Container>
  );
};

export default Form;
