import { StyledHeader, StyledLayout } from "libs/styled-components/styles";
import ForgotPasswordForm from "modules/forgotPassword/components/Form";

const ForgotPassword = () => {
  return (
    <StyledLayout>
      <StyledHeader>
        <h1>Esqueci Minha Senha</h1>
      </StyledHeader>
      <ForgotPasswordForm />
    </StyledLayout>
  );
};
export default ForgotPassword;
