import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem;
  padding-bottom: 10rem;
  background-color: ${({ theme }) => theme.colors.light[50]};

  margin: 0 auto;
  @media (${({ theme }) => theme.medias.desktop}) {
    max-width: 100rem;
    padding: 3rem 10rem;
    padding-bottom: 10rem;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  gap: 2rem;
  width: 100%;
  max-width: 70rem;

  & > h1 {
    font-size: 3rem;
    text-align: center;
    font-family: "Merriweather Regular";
    & > span {
      color: #f76c6f;
    }
  }
  & > p {
    font-size: 1.6rem;
    text-align: center;
    font-family: "Montserrat Regular";
    & > span {
      font-family: "Montserrat Medium";
      color: #f76c6f;
    }
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  gap: 2rem;
  width: 100%;

  & > h2 {
    font-size: 2.4rem;
    text-align: center;
    font-family: "Merriweather Regular";
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (${({ theme }) => theme.medias.desktop}) {
      flex-direction: row;
      justify-content: space-between;
    }
    & > ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      width: 100%;
      list-style: none;
      margin-bottom: 2rem;
      & > li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
        width: 100%;
        padding: 2rem 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.dark[100]};
        & > div {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          gap: 2rem;
          & > img {
            width: 12rem;
            height: 12rem;
            min-height: 12rem;
            min-width: 12rem;
            object-fit: cover;
            border-radius: 1rem;
            box-shadow: 0 0 1rem rgba(108, 108, 108, 0.5);
          }
          & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 1rem;
            width: 100%;
            height: 100%;
            padding: 1rem 0;
            & > h3 {
              font-size: 1.8rem;
              text-align: left;
              font-family: "Montserrat Bold";
            }
            & > p {
              font-size: 1.6rem;
              text-align: left;
              font-family: "Montserrat Regular";
            }
          }
          & > a,
          button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            text-align: center;
            background-color: #f76c6f;
            border-radius: 1rem;
            color: ${({ theme }) => theme.colors.light[50]};
            padding: 1rem 2rem;
            border: none;
            font-size: 1.6rem;
            font-family: "Montserrat Regular";
          }
        }
      }
    }
  }
`;
