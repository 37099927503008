import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 2rem;
  margin-bottom: 6rem;
  & > label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 1.8rem;
    gap: 0.6rem;
    & > span {
      font-size: 1.6rem;
      padding: 1rem;
      background-color: ${({ theme }) => theme.colors.primary[100]};
      border-radius: 0.5rem;
    }
  }
`;

export const CopyButton = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  padding: 1.4rem;
  border-radius: 0.5rem;
  border: none;
  color: ${(p) => p.theme.colors.text.light};
  cursor: pointer;
  background-color: ${(props) =>
    props.isCopied
      ? props.theme.colors.warning[700]
      : props.theme.colors.primary[300]};
`;
