import InputMask from "comigo-tech-react-input-mask";
import { Label } from "./styles";

const InputText = ({
  label,
  field,
  errors,
  register,
  required,
  validate,
  defaultValue,
  placeholder,
  type,
}) => {
  if (type === "tel") {
    // BRL
    const mask = "(99) 99999-9999";
    const pattern = {
      value: /^\([1-9]{2}\) [2-9][0-9]{3,4}-[0-9]{4}$/,
      message: "Telefone inválido",
    };
    return (
      <Label className={errors[field] && "error"}>
        {label}
        <InputMask
          defaultValue={defaultValue}
          placeholder={placeholder}
          mask={mask}
          {...register(field, { required, validate, pattern })}
        />
        {errors[field] && (
          <span>
            {errors[field].message ? errors[field].message : "Obrigatório"}
          </span>
        )}
      </Label>
    );
  }
  return (
    <Label className={errors[field] && "error"}>
      {label}
      <input
        type={type ? type : "text"}
        defaultValue={defaultValue}
        placeholder={placeholder}
        autoComplete="off"
        {...register(field, { required, validate })}
      />
      {errors[field] && (
        <span>
          {errors[field].message ? errors[field].message : "Obrigatório"}
        </span>
      )}
    </Label>
  );
};

export default InputText;
