import { Container, Loader } from "./styles";

const Loading = () => {
  return (
    <Container>
      <Loader />
    </Container>
  );
};
export default Loading;
