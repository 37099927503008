import Header from "components/Header";
import GlobalContext from "context";
import { useContext, useMemo } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import React from "react";

const PrivateRouter = ({ children }) => {
  const { user, authUser } = useContext(GlobalContext);

  const isVerifyEmail = useMemo(() => {
    return authUser ? authUser.emailVerified : false;
  }, [authUser]);

  if (!user && authUser) {
    return (
      <>
        <Header />
        <Layout>
          <Navigate to="/register" />
        </Layout>
      </>
    );
  }

  return (
    <>
      <Header />
      <Layout>
        {authUser && isVerifyEmail ? children : <Navigate to="/login" />}
      </Layout>
    </>
  );
};
export default PrivateRouter;

const Layout = styled.div`
  overflow-y: auto;
  height: -webkit-fill-available;
  height: 100%;
  width: auto;
`;
