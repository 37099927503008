import AppRouter from "routers/AppRouter";
import StyledProvider from "libs/styled-components";
import GlobalContext from "context";
import { useCallback, useContext, useEffect, useState } from "react";
import FirebaseAuthService from "libs/firebase/FirebaseAuthService";
import Loading from "components/Loading";
import FirebaseFirestoreService from "libs/firebase/FirebaseFirestoreService";

function App() {
  const { user, userHandler, authUserHandler } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);

  const init = useCallback(
    async (userFromFirebaseAuth) => {
      authUserHandler(userFromFirebaseAuth);
      try {
        setIsLoading(true);
        if (
          user &&
          userFromFirebaseAuth &&
          user.id === userFromFirebaseAuth.uid
        ) {
          setIsLoading(false);
          return;
        }
        if (userFromFirebaseAuth) {
          const response = await FirebaseFirestoreService.readDocument(
            "partners",
            userFromFirebaseAuth.uid
          );
          const registeredUser = response.data();

          if (registeredUser) {
            userHandler(registeredUser);
          }
        }
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      } catch (error) {
        setIsLoading(false);
        const errorMsg = `init.readDocument [${JSON.stringify(error)}]`;
        console.log(errorMsg);
      }
    },
    [authUserHandler, user, userHandler]
  );

  useEffect(() => {
    console.log("App loaded");
    FirebaseAuthService.subscribeToAuthChanges(init);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="App">
      <StyledProvider>{isLoading ? <Loading /> : <AppRouter />}</StyledProvider>
    </div>
  );
}

export default App;
