export const theme = {
  colors: {
    primary: {
      default: "#1924B1",
      50: "#BCBEEE",
      100: "#AFBECE",
      200: "#6A74F4",
      300: "#436184",
      400: "#2333FF",
      500: "#1924B1",
      600: "#171E79",
      700: "#131968",
      800: "#070C47",
      900: "#030525",
    },
    secondary: {
      default: "#FFD20A",
      50: "#FFEC99",
      100: "#FFE570",
      200: "#FFDD47",
      300: "#FFDA33",
      400: "#FFD61F",
      500: "#FFD20A",
      600: "#F5C800",
      700: "#E0B700",
      800: "#CCA700",
      900: "#A38500",
    },
    success: {
      default: "#02bc73",
      50: "#e1faf4",
      100: "#b4f3e3",
      200: "#83ebd0",
      300: "#51e2bd",
      400: "#2bdcae",
      500: "#06d6a0",
      600: "#05d198",
      700: "#04cc8e",
      800: "#03c684",
      900: "#02bc73",
    },
    warning: {
      default: "#f49d37",
      50: "#fef3e7",
      100: "#fce2c3",
      200: "#face9b",
      300: "#f7ba73",
      400: "#f6ac55",
      500: "#f49d37",
      600: "#f39531",
      700: "#f18b2a",
      800: "#ef8123",
      900: "#ec6f16",
    },
    danger: {
      default: "#df1a2c",
      50: "#fbe4e6",
      100: "#f5bac0",
      200: "#ef8d96",
      300: "#e95f6b",
      400: "#e43c4c",
      500: "#df1a2c",
      600: "#db1727",
      700: "#d71321",
      800: "#d20f1b",
      900: "#ca0810",
    },
    dark: {
      default: "#09131f",
      50: "#e4e6e9",
      100: "#bbc1c7",
      200: "#8e98a2",
      300: "#606f7c",
      400: "#3e5060",
      500: "#1c3144",
      600: "#192c3e",
      700: "#142535",
      800: "#09131f",
      900: "#000000",
    },
    light: {
      default: "#f5f5f5",
      50: "#ffffff",
      100: "#f5f5f5",
      200: "#ebebeb",
      300: "#e0e0e0",
      400: "#eeeeee",
      500: "#dedede",
      600: "#dddddd",
      700: "#dcdcdc",
      800: "#cdcdcd",
      900: "#cccccc",
    },
    text: {
      light: "#f5f5f5",
      dark: "#09131f",
    },
    background: {
      light: "#ffffff",
      dark: "#09131f",
    },
  },
  fontSizes: {
    xs: "1rem",
    sm: "1.4rem",
    md: "1.6rem",
    lg: "2rem",
    xl: "2.4rem",
    xxl: "3.2rem",
    xxxl: "5rem",
    xxxxl: "7rem",
  },
  medias: {
    mobile: "max-width: 480px",
    tablet: "min-width: 481px",
    desktop: "min-width: 769px",
  },
};
