import { Container, ListContainer, TextContainer } from "./styles";
import NewlywedsPDF from "assets/images/newlyweds-pdf.png";
import Demonstration from "assets/images/demonstration.png";
import FirebaseStorageService from "libs/firebase/FirebaseStorageService";

import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Spinner from "components/Spinner";
import { saveLog } from "services/logs";
import GlobalContext from "context";

const PageContent = () => {
  const { user } = useContext(GlobalContext);
  const [isCopied, setIsCopied] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const timeout = useRef();

  const link = useMemo(() => {
    return `https://webapp.capsuladotempo.com/open-event/wtWAjZzdBgdCDZXHoYvB`;
  }, []);

  const onCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setIsCopied(true);

      await saveLog({
        action: "copy-link",
        user: user.id,
        data: {
          link: link,
          email: user.email,
        },
      });
    } catch (error) {
      console.log("savelog.error", error.message);
    }
    timeout.current = setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };

  const onDownload = async () => {
    setIsDownloading(true);
    const url = "marketing/apresentacao-cdt.pdf";
    const filename = "Cápsula do Tempo - Apresentação.pdf";
    try {
      await FirebaseStorageService.downloadFile(url, filename);
      await saveLog({
        action: "download-pdf",
        user: user.id,
        data: {
          fileurl: url,
          filename: filename,
          email: user.email,
        },
      });
    } catch (error) {
      const errorMsg = `onDownload [${JSON.stringify(error)}]`;
      console.log(errorMsg);
      setIsDownloading(false);
    }

    setIsDownloading(false);
  };

  const onOpenLink = async () =>
    await saveLog({
      action: "open-link",
      user: user.id,
      data: {
        link: link,
        email: user.email,
      },
    });

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  return (
    <Container>
      <TextContainer>
        <h1>
          Materia de divulgação da <span>Cápsula do Tempo</span>
        </h1>

        <p>
          Preparamos materiais de apoio para facilitar sua comunicação com seus
          contatos e levar a inovação da <span>Cápsula do Tempo</span> para mais
          pessoas.
        </p>
      </TextContainer>
      <ListContainer>
        <h2>Materiais</h2>
        <div>
          <ul>
            <li>
              <div>
                <img src={Demonstration} alt="cdt gift" />
                <div>
                  <h3>Evento Demonstração</h3>
                  <p>
                    É um evento demonstração que permite gravar vídeo mensagens
                    e assistí-las no mesmo momento.
                  </p>
                </div>
              </div>
              <div>
                <button type="button" onClick={onCopyToClipboard}>
                  {isCopied ? "Copiado" : "Copiar link"}
                </button>
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  onClick={onOpenLink}
                >
                  Abrir
                </a>
              </div>
            </li>
            <li>
              <div>
                <img src={NewlywedsPDF} alt="cdt gift" />
                <div>
                  <h3>Apresentação PDF</h3>
                  <p>
                    Apresentação explicativa sobre a Cápsula do Tempo voltada
                    aos noivos.
                  </p>
                </div>
              </div>
              <div>
                <button onClick={onDownload} disabled={isDownloading}>
                  {isDownloading ? (
                    <Spinner width="8rem" height="2rem" />
                  ) : (
                    "Download"
                  )}
                </button>
              </div>
            </li>
          </ul>
        </div>
      </ListContainer>
    </Container>
  );
};

export default PageContent;
