import firebase from "./FirebaseConfig";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  getBlob,
} from "firebase/storage";

const storage = firebase.storage;

const uploadFile = (file, fullFilePath, progressCallback) => {
  const uploadRef = ref(storage, fullFilePath);
  const uploadTask = uploadBytesResumable(uploadRef, file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );

      progressCallback(progress);
    },
    (error) => {
      throw error;
    }
  );

  return uploadTask.then(async () => {
    const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);

    return downloadUrl;
  });
};

const deleteFile = async (fileDownloadUrl) => {
  const decodedUrl = decodeURIComponent(fileDownloadUrl);
  const startIndex = decodedUrl.indexOf("/o/") + 3;
  const endIndex = decodedUrl.indexOf("?");
  const filePath = decodedUrl.substring(startIndex, endIndex);

  const fileRef = ref(storage, filePath);
  try {
    return await deleteObject(fileRef);
  } catch (error) {
    const errorMsg = `FirebaseStorageService.deleteFile [${JSON.stringify(
      error
    )}]`;
    console.log(errorMsg);
  }
};

const downloadFile = async (file, filename) => {
  const storageRef = ref(storage, file);

  try {
    const blob = await getBlob(storageRef);
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = downloadUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    const errorMsg = `FirebaseStorageService.downloadFile [${JSON.stringify(
      error
    )}]`;
    console.log(errorMsg);
  }
};

const FirebaseStorageService = {
  uploadFile,
  deleteFile,
  downloadFile,
};

export default FirebaseStorageService;
