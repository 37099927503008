import { useInfiniteQuery } from "@tanstack/react-query";
import GlobalContext from "context";
import FirebaseFirestoreService from "libs/firebase/FirebaseFirestoreService";
import { useContext } from "react";

const COLLECTION = "events";
const PER_PAGE = 20;

export const useGetInfinityEvents = (options) => {
  const { user } = useContext(GlobalContext);
  const queryKey = [COLLECTION, "useGetEvents"];
  const queries = [
    {
      field: "refId",
      condition: "==",
      value: user.id,
    },
    {
      field: "status",
      condition: "==",
      value: "published",
    },
  ];

  const fetchEvents = async ({ pageParam = "" }) => {
    try {
      const response = await FirebaseFirestoreService.readDocuments({
        collection: COLLECTION,
        queries,
        perPage: PER_PAGE,
        orderByDirection: "desc",
        orderByField: "createdAt",
        cursorId: pageParam,
      });
      const events = response.docs.map((doc) => {
        const id = doc.id;
        const data = doc.data();
        return {
          ...data,
          id,
        };
      });
      return events;
    } catch (error) {
      const errorMsg = `useGetInfinityEvents.fetchEvents [${JSON.stringify(
        error
      )}]`;
      console.log(errorMsg);
    }
  };

  return useInfiniteQuery(queryKey, fetchEvents, {
    getNextPageParam: (lastPage) => {
      if (!lastPage.length) return undefined;
      const lastDoc = lastPage[lastPage.length - 1];
      return lastDoc.id;
    },
    ...options,
  });
};
