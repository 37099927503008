import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;

  & > h3 {
    margin-top: 8rem;
    margin-bottom: 1rem;
    text-align: center;
  }
`;
