import { ButtonWrapper } from "./styles";

const Button = ({ type, onClick, children, color }) => {
  return (
    <ButtonWrapper type={type} onClick={onClick} color={color}>
      {children}
    </ButtonWrapper>
  );
};

export default Button;
