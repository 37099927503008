import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5rem;
  & > p {
    font-family: "Montserrat Regular";
    text-align: center;
    & > span {
      font-family: "Montserrat SemiBold";
      color: #f76c6f;
    }
    padding: 0 2rem;
    margin-bottom: 4rem;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem;
  gap: 2rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.light[400]};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (${({ theme }) => theme.medias.desktop}) {
    flex-direction: row;
    justify-content: space-between;
  }
  & > ul {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    list-style: none;
    & > li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 100%;

      & > img {
        width: 12rem;
        height: 12rem;
        min-height: 12rem;
        min-width: 12rem;
        object-fit: cover;
        border-radius: 1rem;
        box-shadow: 0 0 1rem rgba(108, 108, 108, 0.5);
      }
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        padding: 1rem 0;
        & > h3 {
          font-size: 1.6rem;
          text-align: center;
          font-family: "Montserrat SemiBold";
        }
        & > p {
          font-size: 1.6rem;
          text-align: center;
          font-family: "Montserrat Regular";
        }
      }
      & > a,
      button {
        min-width: 12.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-align: center;
        background-color: ${({ theme }) => theme.colors.primary[300]};
        border-radius: 1rem;
        color: ${({ theme }) => theme.colors.light[50]};
        padding: 1rem 2rem;
        border: none;
        font-size: 1.6rem;
        font-family: "Montserrat Regular";
      }
    }
  }
`;
