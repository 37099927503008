import Logo from "components/Logo";
import { Container } from "./styles";
import { useNavigate } from "react-router-dom";
import FirebaseAuthService from "libs/firebase/FirebaseAuthService";
import { useContext } from "react";
import GlobalContext from "context";
import Button from "components/Button";
import { useQueryClient } from "@tanstack/react-query";

const Header = () => {
  const { user } = useContext(GlobalContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    queryClient.clear();
    FirebaseAuthService.logoutUser();
  };
  return (
    <Container>
      <div onClick={() => navigate("/")}>
        <Logo />
      </div>
      <div>
        {user && (
          <Button type="button" color="cancel" onClick={handleLogout}>
            Sair
          </Button>
        )}
      </div>
    </Container>
  );
};
export default Header;
