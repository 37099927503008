import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.light[50]};
  padding: 2rem;
  gap: 2rem;
  margin-bottom: 2rem;
`;

export const EventList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  & > ul {
    width: 100%;
    list-style: none;
    & > li {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem;
      border: 1px solid ${({ theme }) => theme.colors.dark[100]};
      border-radius: 0.5rem;
      margin-bottom: 1rem;
    }
  }
`;

export const ListLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0;
  gap: 0.4rem;
  flex-wrap: wrap;
  width: 100%;
  & > label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.6rem;
    font-weight: bold;
    & > span {
      font-size: 1.6rem;
      font-weight: normal;
    }
  }
`;

export const Chip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.success[900]};
  color: ${({ theme }) => theme.colors.light[50]};
  font-size: 1.4rem;
`;
