import { keyframes } from "styled-components";

export const blink = keyframes`
  0% {
    opacity: 1;
        }

  50% {
    opacity: 0;
    }

  100% {
    opacity: 1;
        }
`;

export const loading = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 1rem 0 -1rem;
  }
  40%{
    box-shadow: 0 1rem 0 0;
  }
`;

export const progress = keyframes`
  10%{
    width: 50%;
  }
  60%{
    width: 80%;
  }
  100%{
    width: 99%;
  }

`;
