import { Container } from "./styles";

const Logo = ({ color }) => {
  return (
    <Container color={color}>
      <div>Cápsula do Tempo</div>
      <label>PARCEIROS</label>
    </Container>
  );
};

export default Logo;
