import PageContent from "modules/about/components/PageContent";

const { StyledFullLayout } = require("libs/styled-components/styles");

const Main = () => {
  return (
    <StyledFullLayout>
      <PageContent />
    </StyledFullLayout>
  );
};
export default Main;
