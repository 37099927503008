import { createContext, useState } from "react";

const GlobalContext = createContext({
  user: null,
  userHandler: () => {},
  authUser: null,
  authUserHandler: () => {},
});

export const GlobalContextProvider = (props) => {
  const [user, setUser] = useState();
  const [authUser, setAuthUser] = useState();

  const userHandler = (user) => {
    setUser(user);
  };

  const authUserHandler = (user) => {
    setAuthUser(user);
  };

  // states
  return (
    <GlobalContext.Provider
      value={{
        user,
        userHandler,
        authUser,
        authUserHandler,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
