import { Container, Loader } from "./styles";

const Spinner = ({ color, width = "100%", height = "100%" }) => {
  return (
    <Container width={width} height={height}>
      <Loader color={color} />
    </Container>
  );
};
export default Spinner;
