import { ButtonWrapper } from "./styles";

const LoginButton = ({ type, onClick, children, model }) => {
  return (
    <ButtonWrapper model={model}>
      <button type={type} onClick={onClick}>
        {children}
      </button>
    </ButtonWrapper>
  );
};

export default LoginButton;
