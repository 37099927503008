import Loading from "components/Loading";
import { useMemo } from "react";
import { useGetInfinityEvents } from "services/events";
import { Chip, Container, EventList, ListLine } from "./styles";

const EventsBase = () => {
  const { data, isLoading } = useGetInfinityEvents();
  const items = useMemo(() => {
    return data?.pages;
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <EventList>
        <ul>
          {items?.map((page) => {
            if (page.length === 0) {
              return (
                <ListLine key="no-event">
                  <label>Nenhum evento encontrado</label>
                </ListLine>
              );
            } else {
              return page.map((row) => {
                return (
                  <li key={row.id}>
                    <ListLine>
                      <label>
                        Evento:
                        <span>{row.title}</span>
                      </label>
                    </ListLine>
                    <ListLine>
                      <label>
                        Data de Criação:
                        <span>
                          {new Date(row.createdAt).toLocaleDateString("pt-BR")}
                        </span>
                      </label>
                    </ListLine>
                    <ListLine>
                      <label>
                        Criador:
                        <span>{row.userOwnerName}</span>
                      </label>
                      {row.payment && row.payment === "free-partner" && (
                        <Chip>
                          <span>FREE</span>
                        </Chip>
                      )}
                    </ListLine>
                    <ListLine>
                      <label>
                        Email:
                        <span>{row.userOwnerEmail}</span>
                      </label>
                    </ListLine>
                    <ListLine>
                      <label>
                        Data do evento:{" "}
                        <span>
                          {new Date(row.date).toLocaleDateString("pt-BR", {
                            timeZone: "UTC",
                          })}
                        </span>
                      </label>
                    </ListLine>
                  </li>
                );
              });
            }
          })}
        </ul>
      </EventList>
    </Container>
  );
};

export default EventsBase;
