import GlobalContext from "context";
import {
  StyledContent,
  StyledHeader,
  StyledLayout,
} from "libs/styled-components/styles";
import CompleteForm from "modules/register/components/CompleteForm";
import RegisterForm from "modules/register/components/Form";
import { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  const { user, authUser } = useContext(GlobalContext);

  const isVerifyEmail = useMemo(() => {
    return authUser ? authUser.emailVerified : false;
  }, [authUser]);

  useEffect(() => {
    if (user && authUser) {
      navigate("/");
    }

    if (authUser && !isVerifyEmail) {
      navigate("/login");
    }
  }, [user, authUser, navigate, isVerifyEmail]);

  return (
    <StyledLayout>
      <StyledHeader>
        <h1>Nova Conta</h1>
      </StyledHeader>
      <StyledContent>
        {!user && authUser && <CompleteForm authUser={authUser} />}
        {!user && !authUser && <RegisterForm />}
      </StyledContent>
    </StyledLayout>
  );
};
export default Register;
