import GlobalContext from "context";
import {
  StyledContent,
  StyledHeader,
  StyledLayout,
  StyledTitle,
} from "libs/styled-components/styles";
import PartnerLinkSection from "modules/home/components/PartnerLinkSection";
import EventsBase from "modules/home/components/EventsBase";
import { useContext } from "react";
import SupportContent from "modules/home/components/SupportContent";
import FreeEventsBalance from "modules/home/components/FreeEventsBalance";
import Footer from "components/Footer";

const Home = () => {
  const { user } = useContext(GlobalContext);
  return (
    <StyledLayout>
      <StyledHeader>
        <h2>Olá, {user.name}</h2>
      </StyledHeader>
      <StyledContent>
        <SupportContent />
        <PartnerLinkSection />
        <StyledTitle>Seus Eventos</StyledTitle>
        <FreeEventsBalance />
        <EventsBase />
      </StyledContent>
      <Footer />
    </StyledLayout>
  );
};
export default Home;
