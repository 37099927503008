import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;

  & > h3 {
    margin-top: 8rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    max-width: 40rem;
    width: 40rem;
  }
`;

export const ShowText = styled.div`
  & > label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 2rem;
    & > span {
      padding: 2rem 1rem;
      background-color: ${({ theme }) => theme.colors.warning[50]};
      border-radius: 0.5rem;
      font-size: 2rem;
    }
  }
`;
