import { useMutation } from "@tanstack/react-query";
import GlobalContext from "context";
import FirebaseFirestoreService from "libs/firebase/FirebaseFirestoreService";
import { useContext, useEffect, useState } from "react";

const COLLECTION = "partners";

export const useCreatePartner = (options) => {
  const { userHandler } = useContext(GlobalContext);
  return useMutation(
    async (payload) => {
      try {
        const searchIndexes = [
          payload.name ? payload.name.toLowerCase() : "",
          payload.email ? payload.email.toLowerCase() : "",
          payload.state ? payload.state.toLowerCase() : "",
          payload.city ? payload.city.toLowerCase() : "",
          payload.instagram ? payload.instagram.toLowerCase() : "",
          payload.id ? payload.id : "",
          payload.phone ? payload.phone : "",
          "partner",
        ];
        const data = {
          ...payload,
          freeEvents: 0,
          type: "partner",
          searchIndexes,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        const response = await FirebaseFirestoreService.createDocumentWithId(
          COLLECTION,
          payload.id,
          data
        );

        return response;
      } catch (error) {
        const errorMsg = `useCreatePartner.createDocumentWithId [${JSON.stringify(
          error
        )}]`;
        console.log(errorMsg);
      }
    },
    {
      onSuccess: (result) => {
        userHandler(result);
      },
      ...options,
    }
  );
};

export const getPartnerByEmail = async (email) => {
  try {
    const response = await FirebaseFirestoreService.readDocuments({
      collection: COLLECTION,
      queries: [
        {
          field: "email",
          condition: "==",
          value: email,
        },
      ],
    });

    if (response.docs.length === 0) return null;
    return response.docs[0].data();
  } catch (error) {
    const errorMsg = `getPartnerByEmail.readDocuments [${JSON.stringify(
      error
    )}]`;
    console.log(errorMsg);
  }
};

const IBGE_BASE_URL = "https://servicodados.ibge.gov.br/api/v1/localidades";

export const useGetStates = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [states, setStates] = useState([]);

  useEffect(() => {
    const getStates = async () => {
      try {
        const response = await fetch(`${IBGE_BASE_URL}/estados`);
        const data = await response.json();
        const parsedData = data
          .map((state) => ({
            value: state.sigla,
            label: `${state.sigla} - ${state.nome}`,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setStates(parsedData);
        setIsLoading(false);
      } catch (error) {
        const errorMsg = `getStates [${JSON.stringify(error)}]`;
        console.log(errorMsg);
      }
    };
    getStates();
  }, []);
  return { states, isLoading };
};

export const useGetCities = (state) => {
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCities = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${IBGE_BASE_URL}/estados/${state}/municipios`
        );
        const data = await response.json();
        const parsedData = data
          .map((city) => ({
            value: city.nome,
            label: city.nome,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setCities(parsedData);
        setIsLoading(false);
      } catch (error) {
        const errorMsg = `getCities [${JSON.stringify(error)}]`;
        console.log(errorMsg);
      }
    };
    if (state !== "none" && state) {
      getCities();
    }
  }, [state]);
  return { cities, isLoading };
};
